import React from "react"
import IntegrationTemplate from "../../templates/integration"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import IntegrationStorageContent from 'components/content/integration-storage'
import { meta } from '../../configs/storage-providers';
const ContentPage = () => (
  <IntegrationTemplate
    key="filebase"
    name="Filebase"
    shortDescription="Store website files and database backups on Filebase. Our first Blockchain-powered storage provider."
    website={"https://filebase.com/"}
    links={[{text: 'Documentation', url: '/docs'}]}
    category="Storage"
    logo="filebase-ico.png"
    metaTitle={meta.title.replace('##provider##', 'Filebase')}
    metaDescription={meta.description.replace('##provider##', 'Filebase')}
  >
    <IntegrationStorageContent
      keyRef="filebase"
      service="Filebase"
      provider="Filebase"
      learnTags={['filebase']}
    />
  </IntegrationTemplate>
)

export default ContentPage
